import GradientSection from '../GradientSection';
import HeaderImage from './../../images/header.png';

import './Header.scss';
const Header = ({
  hasSingleColumn,
  children,
  className,
  headerImage,
}: {
  hasSingleColumn: boolean;
  children: React.ReactNode;
  className?: string;
  headerImage?: string;
}) => {
  return hasSingleColumn ? (
    <GradientSection image={headerImage ? headerImage : HeaderImage}>
      <header className="has-single-column">
        <div className="grid grid-cols-1 items-center justify-center text-center">
          {children}
        </div>
      </header>
    </GradientSection>
  ) : (
    <GradientSection image={headerImage ? headerImage : HeaderImage}>
      <header className={className}>
        <div className="grid md:grid-cols-1 xl:grid-cols-2 items-center">
          {children}
        </div>
      </header>
    </GradientSection>
  );
};

export default Header;

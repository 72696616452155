import Layout from './../../components/Layout';
import Header from './../../components/Header';
import Text from './../../components/Text';
import MyAccordion from '../../components/Accordion';
import ContactForm from '../Contact/ContactForm';
import BlogSection from '../Home/BlogSection';
import { accordionItems } from './accordionItems';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor, sectionBlueBackgroundColor } from '../Home';
import { Helmet } from 'react-helmet';

const Faq = () => {
  return (
    <Layout>
      <Helmet>
        <title>Česta pitanja - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="og:title"
          content="Česta pitanja - Upisi na tečaj programiranja"
        />

        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content="Česta pitanja - Upisi na tečaj programiranja"
        />

        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/faq" />

        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta property="og:site_name" content="Kodiraonica" />
        <meta property="og:locale" content="hr_HR" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@kodiraonica" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn={true}>
        <Text type="h1" className="text-white">
          Česta pitanja
        </Text>

        <Text type="p" className="text-white mt-[40px]">
          Ako imaš nedoumice, potraži odgovor ili nam postavi pitanje putem
          kontakt forme.
        </Text>

        {/* <Button
          text="Postavi pitanje"
          className="is-pink is-large w-full mt-[40px] justify-center"
        /> */}
      </Header>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-sm-12 col-lg-10 col-lg-offset-1">
            <MyAccordion items={accordionItems} />
          </div>
        </div>
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBlueBackgroundColor}>
        <ContactForm />
      </GlobalSection>

      <BlogSection />
    </Layout>
  );
};

export default Faq;

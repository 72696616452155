import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './Accordion.scss';
import { ReactSVG } from 'react-svg';
import ChevronDown from './../../images/chevron-down.svg';
import ChevronUp from './../../images/chevron-up.svg';

export type Item = {
  title: string;
  description: () => JSX.Element;
};

const MyAccordion = ({ items }: { items: Item[] }) => {
  return (
    <Accordion allowZeroExpanded className="accordion">
      {items.map((item: Item, index: number) => (
        <AccordionItem className="item" key={index}>
          <AccordionItemHeading>
            <AccordionItemButton className="button flex justify-between">
              {item.title}
              <AccordionItemState>
                {({ expanded }) =>
                  expanded ? (
                    <ReactSVG className="chevron" src={ChevronUp} />
                  ) : (
                    <ReactSVG className="chevron" src={ChevronDown} />
                  )
                }
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="panel">
            {item.description()}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default MyAccordion;

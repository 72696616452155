import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Text from '../../components/Text';

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>404 - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="og:title"
          content="Prijava - Upisi na tečaj programiranja"
        />

        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content="Prijava - Upisi na tečaj programiranja"
        />

        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/prijavi-se" />

        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta
          property="twitter:url"
          content="https://kodiraonica.dev/prijavi-se"
        />

        <meta property="twitter:card" content="summary_large_image" />

        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn>
        <Text type="h1" className="text-white mb-2">
          404
        </Text>
      </Header>
    </Layout>
  );
};

export default NotFoundPage;

export const legalData = [
  {
    title: 'Copyright',
    text: `Autorska prava © Kodiraonica 2023. Sva prava pridržana.
    Sadržaj na web stranici "Kodiraonica", uključujući tekst, slike, grafike i ostali materijal, zaštićen je zakonima o autorskim pravima Republike Hrvatske i međunarodnim sporazumima. Nije dopušteno kopiranje, reprodukcija, distribucija ili bilo koja druga uporaba sadržaja bez izričitog pisanog dopuštenja vlasnika autorskih prava.
    Svi zaštitni znakovi, logotipi i zaštitni nazivi koji se koriste na ovoj web stranici su vlasništvo njihovih odgovarajućih vlasnika i mogu biti zaštićeni pravima intelektualnog vlasništva. Korištenje tih zaštitnih znakova bez prethodnog pisanog odobrenja vlasnika nije dozvoljeno.
    Zahtjevi za dozvolu ili upite u vezi autorskih prava mogu se uputiti na kodiraonica@gmail.com.
    Ova web stranica može sadržavati veze prema vanjskim web stranicama trećih strana. Ne snosimo odgovornost za sadržaj tih stranica niti za bilo kakvu štetu ili gubitke proizašle iz njihove uporabe.
    Ova izjava o autorskim pravima može se povremeno ažurirati i promijeniti. Posljednje izmjene stupaju na snagu od datuma objave.
    `,
  },
  {
    title: 'Cookies',
    text: `Politika kolačića za web stranicu "Kodiraonica"

    Ova politika kolačića objašnjava kako koristimo kolačiće i slične tehnologije na web stranici "Kodiraonica". Pristupanjem i korištenjem naše web stranice, pristajete na upotrebu kolačića u skladu s ovom politikom.
    
    Što su kolačići?
    
    Kolačići su male tekstualne datoteke koje se pohranjuju na vašem uređaju (računalu, pametnom telefonu, tabletu itd.) kada posjetite web stranicu. Oni omogućuju web stranici da prepozna vaš uređaj i prikupi određene informacije kako bi poboljšala vaše korisničko iskustvo.
    
    Kako koristimo kolačiće?
    
    Koristimo kolačiće radi različitih svrha, uključujući:
    
    1. **Analitiku:** Koristimo kolačiće kako bismo prikupili anonimne podatke o tome kako korisnici koriste našu web stranicu. To nam pomaže razumjeti trendove, analizirati posjećenost i poboljšati stranicu.
    
    2. **Funkcionalnost:** Kolačići se koriste za pomoć u određenim funkcionalnostima web stranice, kao što su zapamćivanje vaših postavki ili prijave.
    
    3. **Oglašavanje:** Moguće je da koristimo kolačiće trećih strana kako bismo prilagodili oglase prema vašim interesima na temelju vašeg prethodnog pregledavanja.
    
    Kako upravljati kolačićima?
    
    Većina web preglednika omogućuje vam da upravljate postavkama kolačića. Možete ih obično prilagoditi ili onemogućiti prema svojim preferencama. Imajte na umu da onemogućavanje kolačića može utjecati na funkcionalnost web stranice i korisničko iskustvo.
    
    Više informacija o upravljanju kolačićima potražite u postavkama svog web preglednika.
    
    Promjene u politici kolačića
    
    Ova politika kolačića može se povremeno ažurirati. Promjene stupaju na snagu od datuma objave. Preporučujemo redovito provjeravanje politike kolačića kako biste bili informirani o mogućim promjenama.
    
    Za dodatna pitanja ili zahtjeve u vezi s politikom kolačića, kontaktirajte nas na kodiraonica@gmail.com.
    `,
  },
  {
    title: 'Privacy',
    text: `Politika privatnosti za web stranicu "Kodiraonica"

    Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i štitimo vaše osobne podatke na web stranici "Kodiraonica". Korištenjem naše web stranice pristajete na uvjete ove politike.
  
    
    Prikupljamo određene osobne podatke koje nam dobrovoljno pružite putem naše web stranice, kao što su ime, e-mail adresa i druge informacije potrebne za komunikaciju ili pružanje usluga.
    
    Vaše osobne podatke možemo koristiti za sljedeće svrhe:
    
    1. Pružanje usluga i informacija koje tražite.
    2. Komunikacija s vama putem e-maila ili drugih sredstava.
    3. Poboljšanje naših usluga i prilagodba korisničkog iskustva.
    4. Analiza podataka kako bismo razumjeli trendove i poboljšali stranicu.
    
    Vaše osobne podatke nećemo prodavati, iznajmljivati ​​ili dijeliti s trećim stranama bez vašeg pristanka, osim ako to zahtijeva zakon ili je nužno za pružanje usluga koje ste zatražili.
    
    Poduzimamo razumne tehničke i organizacijske mjere kako bismo zaštitili vaše osobne podatke od gubitka, zloupotrebe ili neovlaštenog pristupa.
    
    Imate pravo zatražiti pristup, ispravak ili brisanje vaših osobnih podataka koje imamo. Također možete zatražiti ograničenje obrade ili ulaganje prigovora na način na koji koristimo vaše podatke.
    
    Ova politika privatnosti može se povremeno ažurirati. Promjene stupaju na snagu od datuma objave. Preporučujemo redovito provjeravanje politike privatnosti kako biste bili informirani o mogućim promjenama.
    
    Za dodatna pitanja ili zahtjeve u vezi s politikom privatnosti, kontaktirajte nas na kodiraonica@gmail.com.
    
    `,
  },
];

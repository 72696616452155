import Text from '../../../components/Text';
import { ReactSVG } from 'react-svg';
import Hand from '../../../images/hand.svg';
import computer from '../../../images/computer.svg';

export const javascriptDescription = () => {
  return (
    <>
      <Text type="p" className="mb-2">
      Ovaj tečaj pruža dublji uvid u razvoj web aplikacija korištenjem JavaScripta u kombinaciji s Firebase API-jem. Kroz ovaj tečaj, usredotočit ćemo se na izvođenje CRUD operacija (Create, Read, Update, Delete) kako bismo stvorili dinamične i interaktivne web aplikacije.
      Očekujte interaktivne vježbe, izazove i projekte koji će vam pomoći da primijenite ono što naučite tijekom tečaja. Naš cilj je da svaki polaznik stekne praktično iskustvo i samopouzdanje u korištenju JavaScripta s Firebase-om za razvoj naprednih web aplikacija. Potrebno predznanje: HTML&CSS, osnove JavaScripta, Git.
      </Text>
     <div className='md:flex'>
      <div className='mr-4'>
        <Text type="h5" className="mb-1">
          Što će polaznici naučiti
        </Text>
        <ul className="modul-list fokus mb-2">
          <li>Dublji uvid u JavaScript</li>
          <li>Upotreba Firebase API-ja</li>
          <li>CRUD operacije</li>
          <li>Clean code arhitektura</li>
          <li>Autentifikacijski sustav</li>
          <li>Pretraživanje objekata</li>
        </ul>
      </div>
     <div>
     <Text type="h5" className="mb-1">
        Raspored predavanja
      </Text>
      <ul className="modul-list fokus mb-2">
        <li>02.03. 2024. od 10h do 12h</li>
        <li>09.03. 2024. od 10h do 12h</li>        
        <li>16.03. 2024. od 10h do 12h</li>
        <li>23.03. 2024. od 10h do 12h</li>
        <li>30.03. 2024. od 10h do 12h</li>
        <li>06.04. 2024. od 10h do 12h</li>
      </ul>
     </div>
     </div>
    </>
  );
};

export const javascriptImageContent = () => {
  return (
    <>
      <section className="row bg-pink py-[15px] mt-2 !mr-0 !ml-0">
        <div className="col-xs-12 mb-2 sm:mb-0 col-sm-6 flex justify-center items-center">
          <ReactSVG className="mr-1" src={Hand} />
          <Text type="p" className="text-white">
            300 eura
          </Text>
        </div>
        <div className="col-xs-12 col-sm-6 flex justify-center items-center">
          <ReactSVG className="mr-1" src={computer} />
          <Text type="p" className="text-white">
            Uživo predavanja u Zagrebu
          </Text>
        </div>
      </section>
    </>
  );
};

import { useEffect } from 'react';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Text from '../../components/Text';
import { legalData } from './legalData';
import { useNavigate, useParams } from 'react-router';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor } from '../Home';
import { Helmet } from 'react-helmet';

const Legal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentLegal = legalData.find(
    (legal) => legal.title.toLowerCase() === params.legalTitle
  );

  useEffect(() => {
    if (!currentLegal) {
      navigate('/');
    }
  }, [currentLegal, navigate]);
  return (
    <Layout>
      <Helmet>
        <title>{currentLegal?.title} - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="og:title"
          content={`${currentLegal?.title} - Upisi na tečaj programiranja`}
        />
        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content={`${currentLegal?.title} - Upisi na tečaj programiranja`}
        />
        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />
        <meta
          property="twitter:url"
          content="https://kodiraonica.dev/prijavi-se"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />
        <meta property="og:site_name" content="Kodiraonica" />
        <meta property="og:locale" content="hr_HR" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kodiraonica.dev/prijavi-se" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn={true}>
        <Text type="h1" className="text-white">
          {currentLegal?.title}
        </Text>
      </Header>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <Text type="h5" className="font-normal">
              {currentLegal?.text}
            </Text>
          </div>
        </div>
      </GlobalSection>
    </Layout>
  );
};

export default Legal;

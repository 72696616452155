import './Footer.scss';
import Text from '../../Text';
import { socialLinks, legalLinks } from './links';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Logo from './../../../images/logo.svg';
import GlobalSection from '../../Section/GlobalSection';
import { sectionBlueBackgroundColor } from '../../../pages/Home';

const Footer = () => {
  return (
    <footer className="bg-blue">
      <GlobalSection backgroundColor={sectionBlueBackgroundColor}>
        {' '}
        <div className="row justify-between">
          <div className="col-xs-12 col-xl-4 justify-center flex items-center mb-3 xl:mb-0">
            <ReactSVG src={Logo} />{' '}
            <Text type="h3" style={{ margin: '0 15px' }}>
              Kodiraonica
            </Text>
          </div>
          <div className="col-xs-12 col-xl-4 justify-center sm:flex items-center mb-3 xl:mb-0">
            {legalLinks.map((link) => (
              <Link key={link.id} to={link.path}>
                {link.text}
              </Link>
            ))}
          </div>
          <div className="col-xs-12 col-xl-4 justify-center flex text-center items-center">
            {socialLinks.map((link) => {
              return (
                <a
                  key={link.id}
                  href={link.path}
                  target="_blank"
                  rel="noreferrer"
                  className="svg-hover"
                >
                  {link.icon}
                </a>
              );
            })}
          </div>
        </div>
      </GlobalSection>
    </footer>
  );
};

export default Footer;

import GradientSection from '../../GradientSection';
import section from './../../../images/section.jpeg';
import Text from '../../Text';
import Button from '../../Form/Button';
import Input from '../../Form/Input';

const CallToAction = ({
  title,
  description,
  buttonText,
  buttonLink,
  hasInput,
  inputPlaceholder,
  inputName,
  onClick,
  onEmailChange,
  successMessage,
}: {
  title: string;
  description?: string;
  buttonText: string;
  buttonLink?: string;
  hasInput?: boolean;
  inputPlaceholder?: string;
  onClick?: () => void;
  inputName?: string;
  onEmailChange?: (e: any) => void;
  successMessage?: string;
}) => (
  <GradientSection image={section}>
    <div className="row">
      <div className="col-xs-12 col-md-10 col-md-offset-1">
        <Text type="h1" className="text-white text-center mb-4 xl:mb-7">
          {title}
        </Text>

        {description && (
          <Text
            type="h4"
            style={{ fontWeight: 'normal', marginBottom: 50 }}
            className="text-white text-center"
          >
            {description}
          </Text>
        )}

        {!hasInput && (
          <Button
            text={buttonText}
            className="is-pink w-full is-large items-center justify-center"
            to={buttonLink}
          />
        )}

        {hasInput && inputPlaceholder && inputName && onEmailChange && (
          <>
            <div className="row">
              <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-offset-0 col-lg-8">
                <Input
                  name={inputName}
                  type="text"
                  placeholder={inputPlaceholder}
                  onChange={onEmailChange}
                />
              </div>
              <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-offset-0 col-lg-4">
                <Button
                  text={buttonText}
                  className="is-pink is-large w-full justify-center items-center"
                  onClick={onClick}
                />
              </div>
            </div>

            {successMessage && (
              <div className="row">
                <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-offset-0 col-lg-8">
                  <Text type="p" className="text-white text-left mt-2">
                    {successMessage}
                  </Text>
                </div>
              </div>
            )}

            <div className="row mt-[40px]">
              <Text type="p" className="text-white text-center">
                *Davanjem svoje email adrese slažete se s primanjem newslettera
                u kojem s vama dijelimo najnovije informacije iz svijeta
                programiranja. U svakom se trenutku možete odjaviti s newsletter
                liste.
              </Text>
            </div>
          </>
        )}
      </div>
    </div>
  </GradientSection>
);

export default CallToAction;

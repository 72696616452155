import Container from '../../Container';
import './GlobalSection.scss';

const GlobalSection = ({
  children,
  className,
  backgroundColor,
}: {
  children: React.ReactNode;
  className?: string;
  backgroundColor: string;
}) => {
  const klasa = className ? `${className} global__section` : 'global__section';
  return (
    <section className={klasa} style={{ backgroundColor: backgroundColor }}>
      <Container>{children}</Container>
    </section>
  );
};

export default GlobalSection;

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Text from '../../components/Text';
import { ReactSVG } from 'react-svg';
import AboutImage from '../../images/about-header.svg';
import ImageSection from '../../components/Section/ImageSection';
import Antonija from './../../images/Antonija.png';
import Matija from './../../images/Matija.png';
import Denis from './../../images/Denis.png';
import Petra from './../../images/Petra.png';
import linkedin from './../../images/linkedin-blue.svg';
import section from './../../images/section.jpeg';
import CardsSection from '../../components/Section/CardsSection';
import GradientSection from '../../components/GradientSection';
import { aboutCards } from './cards';
import CallToAction from '../../components/Layout/CallToAction';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor } from '../Home';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title>O nama - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />
        <meta
          property="og:title"
          content="O nama - Upisi na tečaj programiranja"
        />

        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content="O nama - Upisi na tečaj programiranja"
        />

        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/about" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="twitter:url" content="https://kodiraonica.dev/about" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="twitter:image:alt" content="Kodiraonica" />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="628" />

        <meta property="og:site_name" content="Kodiraonica" />
        <meta property="og:locale" content="hr_HR" />

        <meta property="twitter:creator" content="@kodiraonica" />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn={false}>
        <div>
          <Text className="text-white mb-4 xl:mb-7" type="h1">
            Siguran put do prvog programerskog posla
          </Text>
          <Text type="h5" className="text-white mb-3 font-normal">
            Nezadovoljstvo na poslu, završetak fakulteta koji nije ispravan
            izbor, želja za vremenskom, prostornom i financijskom slobodom...
          </Text>

          <Text type="h5" className="text-white mb-3">
            <b>Zvuči poznato?</b>
          </Text>

          <Text type="h5" className="text-white mb-3 font-normal">
            Vjerujemo kako je nezadovoljstvo samo pokretač pozitivne promjene. I
            da nikada nije kasno za novi smjer.
          </Text>

          <Text type="h5" className="text-white">
            <b>Vjerujemo - jer smo i sami to iskusili.</b>
          </Text>
        </div>

        <div className="justify-end hidden xl:flex">
          <ReactSVG src={AboutImage} />
        </div>
      </Header>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-sm-12">
            <Text type="h1" className="text-blue text-center">
              Danas podučavamo nove generacije programera, kako bi i i oni
              iskusili slobodu koju živimo!
            </Text>
          </div>
        </div>
      </GlobalSection>

      <GradientSection image={section}>
        <CardsSection
          cards={aboutCards.slice(0, 2)}
          descriptionStyle={{ textAlign: 'left' }}
          headingStyle={{ textAlign: 'left' }}
        />
        <CardsSection
          cards={aboutCards.slice(2, 4)}
          descriptionStyle={{ textAlign: 'left' }}
          headingStyle={{ textAlign: 'left' }}
        />
      </GradientSection>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-xs-12">
            <Text type="h1" className="text-blue text-center mb-4 xl:mb-7">
              Upoznaj naš tim
            </Text>
          </div>
        </div>

        <div className="mb-4 xl:mb-7">
          <ImageSection
            heading="Antonija Šimić"
            description="Antonija je samouka programerka s velikom strašću prema CSS-u i JavaScriptu. Iako su joj mnogi govorili da je programiranje teško i da neće uspjeti bez faksa, odvažila se i davne 2013. upisala “Uvod u web programiranje”. Od tada je radila s tvrtkama iz gotovo svih kontinenata, mentorirala više od 200 učenika i predavala uživo u europskim školama.
Prije nekoliko godina odlučila je pokrenuti Kodiraonicu s ciljem da potakne i druge da promijene život. Želi uvesti širok i šarolik spektar ljudi u kodiranje, a polaznicima pristupa s puno pažnje i strpljenja."
            imageSrc={Antonija}
            subHeading="Predavačica i osnivačica"
            quote="Programiranje u početku može biti zastrašujuće, ali uz moju pomoć lako ćemo savladati strahove!"
            icon={<ReactSVG src={linkedin} />}
            iconLink="https://www.linkedin.com/in/antonija-simic/"
          />
        </div>

        <div className="mb-4 xl:mb-7">
          {' '}
          <ImageSection
            heading="Matija Katić"
            description="Matija se profesionalno bavi programiranjem 8 godina, a izrađivao je sve - od desktop aplikacija do fintech sustava. Njegov je put započeo igrom Elder Scrolls III: Morrowind, gdje je putem Construction seta mogao napraviti što je želio. Jedino što nije znao je - programiranje.
              Kao osnovnoškolac, zaputio se u knjižnicu i posudio knjigu o QBasicu. Kroz daljnje školovanje susreo se s programskim jezikom C i web developmentom. Nakon neuspjeha na fakultetu, samostalno je naučio programirati i savladao Java, C#, Ruby on Rails programske jezike, ali i JavaScript te mnoge frameworkove vezane uz spomenute tehnologije. Uz veliko iskustvo i stečene vještine, u kombinaciji s pedagoškim znanjem, odlučio se usmjeriti na podučavanje."
            imageSrc={Matija}
            subHeading="Predavač"
            quote="U svojem procesu učenja shvatio sam kako to znanje prenijeti drugima!"
            icon={<ReactSVG src={linkedin} />}
            iconLink="https://www.linkedin.com/in/matijakatic/"
            isReverse={true}
          />
        </div>

        <div className="mb-4 xl:mb-7">
          <ImageSection
            heading="Denis Martin Budinski"
            description="Denis je full-stack programer u tvrtki End Coding d.o.o. Programirati je počeo u srednjoj školi i odmah se pronašao u ovom pozivu. Upisao je FOI kako bi produbio svoje znanje, a odmah je pronašao posao kao softver developer. Do danas je naučio mnoge programske jezike, a specijalizirao se za JavaScript (web development) i C# (software development). Radio je na mnogim projektima za razne naručitelje.
              Trenutno razvija vlastiti softverski proizvod i smatra to najvećim projektom, a vjeruje i najvećim postignućem u svojoj karijeri. Zadnjih godinu dana predaje u Kodiraonici što ga čini neizmjerno sretnim jer može prenijeti svoje znanje na mlade programere."
            imageSrc={Denis}
            subHeading="Predavač"
            quote="Želim olakšati početak mladim programerima i pomoći im u daljnjem usavršavanju!"
            icon={<ReactSVG src={linkedin} />}
            iconLink="https://www.linkedin.com/in/denis-martin-budinski/"
          />
        </div>

        <div>
          {' '}
          <ImageSection
            heading="Petra Sedlanić Kereša"
            description="Za sadržaj na našim društvenim mrežama zaslužna je Petra koja redovito dijeli korisne informacije i prednosti svijeta programiranja. Osluškuje pitanja, dvojbe i komentare te ih prosljeđuje predavačima kako bi unaprijedili tečajeve sukladno potrebama zajednice okupljene oko Kodiraonice.
              Petra je vlasnica obrta za marketinške usluge i mentorica za marketing i osobno brendiranje. Radi s poduzetnicima i malim biznisima. Preuzima njihove marketinške kanale ili ih savjetuje kako da to samostalno rade i izgrade jedinstveni brend, u skladu s njihovim vrijednostima i poslovnim ciljevima."
            imageSrc={Petra}
            subHeading="Stručnjakinja za marketing"
            quote="Društvene mreže omogućavaju mi da otkrijem želje i potrebe zajednice okupljene oko Kodiraonice."
            icon={<ReactSVG src={linkedin} />}
            iconLink="https://www.linkedin.com/in/petra-sedlanić/"
            isReverse={true}
          />
        </div>
      </GlobalSection>

      <CallToAction
        title="Želiš predavati u Kodiraonici?"
        description="Pridruži se našem timu i kroz podučavanje osjeti svoj veliki profesionalni rast! Pošalji nam svoj CV i portfolio. Veselimo se upoznati te!"
        buttonText="Javi nam se"
        buttonLink="mailto:kodiraonica@gmail.com"
      />
    </Layout>
  );
};

export default About;

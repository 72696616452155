import Label from '../Label';
import Select from 'react-select';
import './Select.scss';
import { useEffect, useRef } from 'react';

type SelectProps = {
  name: string;
  label: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  required: boolean;
  rules: string;
  onChange?: (value: any) => void;
};

const MySelect = ({ name, label, options, required, onChange }: SelectProps) => {
  const fakeSelect = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const inputs = document.querySelectorAll('.form__group input');

    inputs.forEach((input, index) => {
      input.setAttribute('name', `name-${index}`);
      input.parentElement?.setAttribute('id', `input__parent`);
    });
  });


  return (
    <div className="form__group">
      <Label forAttribute={name} isRequired={required}>
        {label}
      </Label>
      <div className="my-select" style={{ width: '100%' }} ref={fakeSelect}>
        <Select
          options={options}
          placeholder={label}
          name={name}
          onChange={(e) => onChange && onChange(e)}
          styles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
              display: 'block',
            }),
            control: (provided) => ({
              ...provided,
              border: 'none',
              boxShadow: 'none',
              borderRadius: 0,
              width: '100%',
              padding: '8px 12px',
              '&:focus': {
                borderBottom: '2px solid #E3386A',
              },
              '&:active': {
                borderBottom: '2px solid #E3386A',
              },
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: '#E3386A',
            }),
            menu: (provided) => ({
              ...provided,
              borderRadius: 0,
              marginTop: 0,
              width: '100%',
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? '#fff' : '#000',
              backgroundColor: state.isSelected ? '#E3386A' : '#fff',
              padding: '16px 20px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#E3386A',
                color: '#fff',
              },
            }),
          }}
        />
      </div>
    </div>
  );
};

export default MySelect;

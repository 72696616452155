import Text from '../../components/Text';
import Label from '../../components/Form/Label';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Textarea from '../../components/Form/Textarea';
import { useEffect } from 'react';
import kwesforms from 'kwesforms';

const ContactForm = () => {
  useEffect(() => {
    kwesforms.init();
  }, []);
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-xl-8 col-xl-offset-2 text-center">
        <Text type="h1" className="text-white mb-2">
          Imaš pitanje?
        </Text>
        <Text type="h5" className="text-white font-normal mb-4 xl:mb-7">
          Rado ćemo odgovoriti na tvoj upit.
        </Text>
      </div>
      <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2">
        <form
          className="kwes-form"
          action="https://kwesforms.com/api/foreign/forms/cRMJMsYpIJD9YJm60Hnf"
          success-message="Vaša poruka je poslana!"
          error-message="Sva polja moraju biti validna."
        >
          <div className="mb-3">
            <Label forAttribute="ime" className="text-white" isRequired>
              Ime
            </Label>
            <Input
              rules="required"
              name="ime"
              placeholder="Ivana Gospić"
              type="text"
            />
          </div>

          <div className="mb-3">
            <Label forAttribute="email" className="text-white" isRequired>
              E-mail address
            </Label>
            <Input
              rules="required|email"
              name="email"
              placeholder="mail@gmail.com"
              type="text"
            />
          </div>

          <div className="mb-3">
            <Label forAttribute="poruka" className="text-white" isRequired>
              Vaša poruka
            </Label>
            <Textarea
              rules="required"
              name="poruka"
              cols={10}
              rows={6}
              placeholder="Poštovani, zanima me"
            />
          </div>

          <div className="flex justify-end">
            <Button type="submit" text="Pošalji upit" className="is-pink" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

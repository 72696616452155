import Text from '../../../components/Text';
import FrontendImage from './../../../images/frontend-modul.png';
import BackendImage from './../../../images/backend-modul.png';
import FEKeys from '../../../images/frontend-modul-keys.png';
import BeKeys from '../../../images/backend-modul-keys.png';
import Denis from '../../../images/Denis.png';
import Matija from '../../../images/Matija.png';
import FEHeader from '../../../images/fe-header.png';
import BEHeader from '../../../images/be-header.png';

export const modules = [
  {
    headerImage: FEHeader,
    name: 'Frontend programiranje',
    path: '/modul',
    moduleId: 'frontend',
    duration: '168 sati (oko 6 mjeseci)',
    time: '2-3 sata po predavanju',
    price: '1500 eura bez PDV-a',
    teacher: 'Matija Katić',
    teacherDescription:
      'Matija se profesionalno bavi programiranjem 8 godina, a izrađivao je sve - od desktop aplikacija do fintech sustava. Samostalno je naučio programirati i savladao Java, C#, Ruby on Rails programske jezike, ali i JavaScript te mnoge frameworkove vezane uz spomenute tehnologije.',
    teacherImage: Matija,
    title:
      'Pitaš se što radi front-end developer/ka? Najjednostavnije rečeno - stvara sve što korisnik vidi na stranici, kreirajući sponu između dizajna i koda.',
    subTitle: () => (
      <>
        <Text type="p" className="mb-[25px]">
          Primarno se fokusira na korisničko iskustvo i gradi elemente koji su
          izravno povezani s krajnjim korisnikom.
        </Text>
        <Text type="p" className="mb-[25px]">
          Osnovni programski jezici koji se koriste su <b>HTML</b>, <b>CSS</b> i{' '}
          <b>JavaScript</b>, dok su neki od popularnih razvojnih okvira{' '}
          <b>React</b> i<b>Angular</b>.
        </Text>

        <Text type="p" className="mb-[25px]">
          Online tečaj front-end programiranja polaznicima korak po korak
          pokazuje <b>kako postati programer od nule</b>.
        </Text>

        <Text type="p" className="mb-[25px]">
          Program je koncipiran kao sveobuhvatan pregled polja front-end
          developmenta. Nakon usvajanja osnovne teorije, fokus je na primjeni
          znanja, kako bi polaznici tijekom tečaja stekli vještine potrebne za
          prvi posao programera.
        </Text>
      </>
    ),
    image: FrontendImage,
    keyImage: FEKeys,
    learningOutcomes: () => {
      return (
        <ul className="modul-list">
          <li>Razumijevanje osnova web tehnologija</li>
          <li>Izrada responzivnih web stranica</li>
          <li>Upotreba Angular front-end okvira</li>
          <li>Optimizacija performansi</li>
          <li>Integracija s API-ima</li>
          <li>Povezivanje s back-endom</li>
          <li>Iskustvo korisničkog sučelja iskustva</li>
          <li>Testiranje i ispravci</li>
        </ul>
      );
    },
    program: [
      {
        title: '1. HTML Osnove',
        description: 'Nauči osnove HTML-a i izradi svoju prvu web stranicu.',
        titleType: 'h4',
      },
      {
        title: '2. CSS',
        description:
          'Upoznaj se s CSS-om i nauči kako stilizirati svoje web stranice.',
        titleType: 'h4',
      },
      {
        title: '3. JavaScript',
        description:
          'Upoznaj moćni JavaScript programski jezik i njegove mogućnosti.',
        titleType: 'h4',
      },
      {
        title: '4. Responsive Design',
        description: 'Nauči sve tehnike za izradu responzivnih web stranica.',
        titleType: 'h4',
      },
      {
        title: '5. UX/UI Design',
        description:
          'Prvi koraci u dizajniranju korisničkog sučelja i iskustva.',
        titleType: 'h4',
      },
      {
        title: '6. Version Control',
        description:
          'Upoznaj se s alatima za upravljanje verzijama i suradnju na projektima.',
        titleType: 'h4',
      },
    ],
    accordionItems: [
      {
        title: 'Kako izgleda tečaj?',
        description: () => (
          <Text type="p">
            Tečajevi se održavaju online, uživo na Zoom platformi. Potrebno ti
            je računalo s kamerom i mikrofonom. Predavanja su tri puta tjedno, a
            za svaki susret potrebno je odvojiti 2 do 3 sata.
          </Text>
        ),
      },
      {
        title: 'Koliko traje tečaj?',
        description: () => (
          <Text type="p">
            Tečaj traje otprilike 6 mjeseci, ovisno o dinamici. Toliko nam je
            potrebno da usvojimo i primijenimo znanje potrebno za prvi posao u
            programiranju.
          </Text>
        ),
      },

      {
        title: 'Koja je cijena tečaja Front-end programiranja?',
        description: () => <Text type="p">Cijena tečaja je 1500 eura bez PDV-a. </Text>,
      },
    ],
  },
  {
    headerImage: BEHeader,
    name: 'Backend programiranje',
    path: '/modul',
    moduleId: 'backend',
    duration: '168 sati (oko 6 mjeseci)',
    time: '2-3 sata po predavanju',
    price: '2000 eura bez PDV-a',
    teacher: 'Denis Martin Budinski',
    teacherDescription:
      'Denis je full-stack programer koji se specijalizirao za JavaScript (web development) i C# (software development). Radio je na mnogim projektima za razne naručitelje, a trenutno razvija vlastiti softverski proizvod',
    teacherImage: Denis,
    title:
      'Back-end programer/ka zadužen/a je za sve što korisnik ne vidi, a što osigurava pravilno funkcioniranje stranice.',
    subTitle: () => (
      <>
        <Text type="p" className="mb-[25px]">
          “Bekendaši” koriste kod kako bi izgradili i održali mehanizme koji
          procesuiraju podatke i izvršavaju akcije na stranici.{' '}
          <b>
            Fokusiraju se na baze podataka, skriptiranje i arhitekturu web
            stranice.
          </b>
        </Text>
        <Text type="p" className="mb-[25px]">
          Online tečaj back-end programiranja za početnike uključuje
          sveobuhvatan pregled -{' '}
          <b>od osnovne teorije do najnovije tehnologije.</b>
        </Text>

        <Text type="p" className="mb-[25px]">
          Uz iskusno vodstvo i mentorstvo, backend programiranje će od
          zanimljivog pojma postati tvoj posao. Fokus tečaja je na primjeni
          znanja i izradi prvog portfolija kako bi potencijalni poslodavci i
          klijenti vidjeli konkretne rezultate tvog rada.
        </Text>
      </>
    ),
    image: BackendImage,
    keyImage: BeKeys,
    learningOutcomes: () => {
      return (
        <ul className="modul-list">
          <li>Osnovno razumijevanje C# jezika</li>
          <li>Objektno orijentirano programiranje</li>
          <li>Rad s kolekcijama i podacima</li>
          <li>Grafičko korisničko sučelje (GUI) razvoj</li>
          <li>Rad s bazama podataka</li>
          <li>Obrada iznimki</li>
          <li>Razvoj konzolnih aplikacija</li>
          <li>Testiranje i debagiranje</li>
        </ul>
      );
    },
    program: [
      {
        title: '1. C# Osnove',
        description: 'Nauči osnove C# programskog jezika.',
        titleType: 'h4',
      },
      {
        title: '2. OOP',
        description:
          'Istraži OOP koncepte poput klasa, objekata i nasljeđivanja.',
        titleType: 'h4',
      },
      {
        title: '3. Podaci i kolekcije',
        description:
          'Upoznaj se s kolekcijama i podacima te nauči kako ih koristiti u C#.',
        titleType: 'h4',
      },
      {
        title: '4. GUI Aplikacije',
        description: 'Izradi svoju prvu aplikaciju s grafičkim sučeljem.',
        titleType: 'h4',
      },
      {
        title: '5. Baze podataka',
        description: 'Nauči kako raditi s bazama podataka u C#.',
        titleType: 'h4',
      },
      {
        title: '6. Testiranje i debagiranje',
        description: 'Upoznaj se s osnovama testiranja i debagiranja softvera.',
        titleType: 'h4',
      },
    ],
    accordionItems: [
      {
        title: 'Kako izgleda tečaj?',
        description: () => (
          <Text type="p">
            Tečajevi se održavaju online, uživo na Zoom platformi. Potrebno ti
            je računalo s kamerom i mikrofonom. Predavanja su tri puta tjedno, a
            za svaki susret potrebno je odvojiti 2 do 3 sata.
          </Text>
        ),
      },
      {
        title: 'Koliko traje tečaj?',
        description: () => (
          <Text type="p">
            Tečaj traje otprilike 6 mjeseci, ovisno o dinamici. Toliko nam je
            potrebno da usvojimo i primijenimo znanje potrebno za prvi posao u
            programiranju.
          </Text>
        ),
      },
      {
        title: 'Koja je cijena tečaja backend programiranja?',
        description: () => <Text type="p">Cijena tečaja je 2000 eura bez PDV-a. </Text>,
      },
    ],
  },
];

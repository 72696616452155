import Text from '../../components/Text';
import CardsSection from '../../components/Section/CardsSection';
import Button from '../../components/Form/Button';
import { ReactSVG } from 'react-svg';
import ArrowRightBlack from './../../images/arrow-right-black.svg';
import { blogCards } from './cards';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor } from './index';

const BlogSection = () => {
  return (
    <GlobalSection backgroundColor={sectionBackgroundColor}>
      <Text type="h1" className="text-center text-blue mb-[12px]">
        Mi pišemo blog
      </Text>
      <Text
        type="h5"
        style={{
          fontWeight: 'normal',
          textAlign: 'center',
        }}
        className="mb-4 xl:mb-7"
      >
        Inspiriraj se i motiviraj uz iskustva i savjete iskusnih programera!
      </Text>
      <CardsSection cards={blogCards} />
      <Button
        text="Pogledaj sve postove"
        icon={<ReactSVG src={ArrowRightBlack} />}
        className="is-black float-right"
        to="https://blog.kodiraonica.dev/"
      />
    </GlobalSection>
  );
};

export default BlogSection;

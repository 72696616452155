import { useEffect } from 'react';
import Footer from './Footer';
import Nav from './Nav';

const Layout = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Nav />
      {children}
      <Footer />
    </main>
  );
};

export default Layout;

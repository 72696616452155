import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Text from './../../components/Text';
import { socialLinks } from '../../components/Layout/Footer/links';
import Container from '../../components/Container';
import { Link } from 'react-router-dom';
import { people } from './people';
import './Contact.scss';
import Spacer from '../../components/Layout/Spacer';
import ContactForm from './ContactForm';
import Image from '../../components/Image';
import CallToAction from '../../components/Layout/CallToAction';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor, sectionBlueBackgroundColor } from '../Home';
import ContactHeaderImage from '../../images/contact-header.png';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Kontakt - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="og:title"
          content="Kontakt - Upisi na tečaj programiranja"
        />
        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content="Kontakt - Upisi na tečaj programiranja"
        />

        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/kontakt" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="twitter:card" content="summary_large_image" />

        <meta property="twitter:creator" content="@kodiraonica" />

        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="628" />

        <meta property="og:site_name" content="Kodiraonica" />

        <link rel="canonical" href="https://kodiraonica.dev/kontakt" />

        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header hasSingleColumn={true} headerImage={ContactHeaderImage}>
        <Text type="h1" className="text-white">
          Kontaktiraj nas putem maila, forme ili društvenih mreža!
        </Text>
      </Header>

      <section className="bg-blue" style={{ padding: '50px 20px' }}>
        <Container>
          <div className="row">
            <div className="col-sm-12 text-center flex justify-center items-center">
              {socialLinks.map((link) => (
                <Link
                  key={link.id}
                  to={link.path}
                  className="mr-1 xl:mr-2 svg-hover"
                >
                  {link.icon}
                </Link>
              ))}
            </div>
          </div>
        </Container>
      </section>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          {people.map((person, index) => (
            <div
              className="col-xs-10 col-xs-offset-1 col-md-offset-0 col-md-3 col-xl-3 text-center mb-4 md:mb-0"
              key={index}
            >
              <div className="person-image">
                <Image src={person.img} alt={person.name} />
              </div>
              <Text type="h4">{person.name}</Text>
              <Text type="p" className="mt-[10px]">
                {person.title}
              </Text>
              <a
                className="block text-blue mt-[10px]"
                href={`mailto:${person.mail}`}
              >
                {person.mail}
              </a>
              <a
                href={person.linkedin}
                target="_blank"
                rel="noreferrer"
                className="text-blue mt-[10px] inline-block"
              >
                Linkedin profil
              </a>
            </div>
          ))}
        </div>
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBlueBackgroundColor}>
        <ContactForm />
      </GlobalSection>

      <Spacer height={80} backgroundColor="#ffffff" />

      <CallToAction
        title="Želiš predavati u Kodiraonici?"
        description="Pridruži se našem timu i kroz podučavanje osjeti svoj veliki profesionalni rast! Pošalji nam svoj CV i portfolio. Veselimo se upoznati te!"
        buttonText="Javi nam se"
        buttonLink="mailto:kodiraonica@gmail.com"
      />

      <Spacer height={80} backgroundColor="#ffffff" />
    </Layout>
  );
};

export default Contact;

import { useState } from 'react';
import Button from '../../Form/Button';
import Dropdown from './../Nav/Dropdown';
import { Link } from 'react-router-dom';
import { links } from './../Nav/Links';
import './Sidebar.scss';

const Sidebar = () => {
  const sidebarLinks = links.filter((link) => link.isOnSidebar);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  return (
    <aside>
      <Button
        text="menu"
        className="is-blue is-white"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      />

      <div
        className={`${isSidebarVisible ? 'is-open' : 'is-closed'} sidebar-menu`}
      >
        <ul className="sidebar-list">
          {' '}
          {sidebarLinks.map((link, index) => {
            if (link.path) {
              return (
                <li key={index}>
                  <Link className="sidebar-link" to={link.path}>
                    {link.name}
                  </Link>
                </li>
              );
            }

            if (link.sublinks) {
              return (
                <Dropdown
                  key={index}
                  dropdownName="Tečajevi"
                  options={link.sublinks}
                />
              );
            }

            return null;
          })}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;

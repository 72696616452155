import Layout from '../../components/Layout';
import Text from '../../components/Text';
import Header from '../../components/Header';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor } from '../Home';
import ImageSection from '../../components/Section/ImageSection';
import gitImage from '../../images/git.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { javascriptDescription, javascriptImageContent } from './AdvancedJavascript';
import CallToAction from '../../components/Layout/CallToAction';

const FokusModul = () => {
  return (
    <Layout>
      <Helmet>
        <title>Fokus tečajevi</title>
        <meta
          name="description"
          content="Prijavi se na kratki tečaj programiranja i poboljšaj svoje vještine"
        />
        <meta property="og:title" content="Fokus tečajevi" />

        <meta
          property="og:description"
          content="Prijavi se na kratki tečaj programiranja i poboljšaj svoje vještine"
        />

        <meta property="twitter:title" content="Fokus tečajevi" />

        <meta
          property="twitter:description"
          content="Prijavi se na kratki tečaj programiranja i poboljšaj svoje vještine"
        />

        <meta
          property="og:url"
          content="https://kodiraonica.dev/fokus-tecajevi"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta
          property="twitter:url"
          content="https://kodiraonica.dev/fokus-tecajevi"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />

        <meta property="twitter:image:alt" content="Kodiraonica" />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="628" />

        <meta property="og:site_name" content="Kodiraonica" />
        <meta property="og:locale" content="hr_HR" />

        <meta property="twitter:creator" content="@kodiraonica" />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn>
        <div>
          <Text className="text-white mb-4 xl:mb-7" type="h1">
            Fokus tečajevi
          </Text>
          <Text type="h5" className="text-white mb-3 font-normal">
            Fokus tečajevi programiranja su{' '}
            <b>kratki i intenzivni programi učenja</b>. 
          </Text>

          <Text type="h5" className="text-white mb-3 font-normal">
            Uz jasno vodstvo i mentorstvo, s lakoćom savladaj{' '}
            <b>specifične teme &nbsp;</b>
            važne za nadogradnju znanja iz područja programiranja!{' '}
            <b>Tečajevi traju mjesec dana</b>, a predavači su iskusni stručnjaci
            spremni odgovoriti na sva pitanja.
          </Text>
        </div>
      </Header>

      <GlobalSection backgroundColor="#ffffff">
        <ImageSection
          heading="Napredni Javascript"
          description={javascriptDescription()}
          imageSrc={gitImage}
          imageContent={javascriptImageContent()}
        />

      </GlobalSection>

      <CallToAction
        buttonLink="/prijavi-se"
        title="Početak tečaja"
        buttonText="Upisni obrazac"
        description="Tečaj naprednog Javascripta počinje 02. ožujka 2024. godine! Lokaciju predavanja ćemo ti javiti nakon prijave."
      />

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        &nbsp;
      </GlobalSection>
    </Layout>
  );
};

export default FokusModul;

export const aboutCards = [
  {
    titleType: 'h3',
    title: 'Praksa, praksa, praksa',
    description:
      'Kodiraonica je online škola programiranja koja na prvo mjesto stavlja primjenu znanja. Tečajevi front-end i back-end programiranja i kraći tematski tečajevi uključuju osnovnu teoriju, a potom fokus usmjeravamo na konkretne zadatke i projekte. Polaznici izrađuju svoj prvi developerski portfolio, što im omogućava da se odmah predstave potencijalnim poslodavcima i klijentima.',
  },
  {
    titleType: 'h3',
    title: 'Vodimo vas sigurno do cilja',
    description:
      'Iako su informacije dostupne svima online, samostalno učenje programiranja donosi brojne prepreke. Programeri početnici lako se izgube u moru informacija. U Kodiraonici znamo što trebate znati. Uz naše vodstvo i jasno definiranu strukturu tečaja sigurno ćete stići do cilja. Tečajeve smo koncipirali u skladu s time što bi i mi željeli koristiti kada smo se pitali kako početi programirati.',
  },
  {
    titleType: 'h3',
    title: 'Mentorstvo je ključ napretka',
    description:
      'Rad na konkretnim projektima uči nas da shvaćanje teorije ne znači nužno i jasnoću prilikom primjene. Zato smo tu za naše polaznike tijekom cijelog tečaja. Odgovaramo na pitanja, pomažemo riješiti izazove i poteškoće. Radimo u manjim grupama kako bismo mogli posvećeno mentorirati svakog polaznika.',
  },
  {
    titleType: 'h3',
    title: 'Zajednica samoukih programera',
    description:
      'Kodiraonica nije samo škola, nego zajednica programera u kojoj su iskusniji kolege uvijek spremni pomoći i podijeliti znanje. Na tečajevima se polaznici međusobno povezuju te nauče gdje ubuduće potražiti savjet. Programiranje za početnike postaje lakše kada nisi sam/sama na tom putu.',
  },
];

import Layout from '../../components/Layout';
import Text from '../../components/Text';
import Header from '../../components/Header';
import Input from '../../components/Form/Input';
import Label from '../../components/Form/Label';
import Select from '../../components/Form/Select';
import Textarea from '../../components/Form/Textarea';
import Button from '../../components/Form/Button';
import SignupImage from '../../images/signup.png';
import Image from '../../components/Image';
import GlobalSection from '../../components/Section/GlobalSection';
import { sectionBackgroundColor } from '../Home';
import SignupHeaderImage from '../../images/signup-header.png';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import kwesforms from 'kwesforms';

const Signup = () => {
  const [selectedCourse, setSelectedCourse] = useState('frontend');
  useEffect(() => {
    kwesforms.init();
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Prijava - Upisi u programiranje</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="og:title"
          content="Prijava - Upisi na tečaj programiranja"
        />
        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta
          property="twitter:title"
          content="Prijava - Upisi na tečaj programiranja"
        />
        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/prijavi-se" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta
          property="twitter:url"
          content="https://kodiraonica.dev/prijavi-se"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
        />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://kodiraonica.dev/prijavi-se" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn={true} headerImage={SignupHeaderImage}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-8 col-lg-offset-2">
            <Text className="text-white" type="h1" style={{ marginBottom: 60 }}>
              Samo te jedan korak dijeli od nove karijere!
            </Text>
            <Text
              type="p"
              className="text-white"
              style={{
                marginBottom: 30,
                marginTop: 30,
                fontWeight: 'normal',
              }}
            >
              Ispuni kontakt obrazac i postani web developer/ka!
            </Text>

            <Text type="p" className="text-white">
              Ako nisi siguran/sigurna koji je tečaj pravi odabir za tebe, u
              napomeni nam to i napiši. Dogovorit ćemo besplatno savjetovanje s
              našim predavačima i pronaći pravo rješenje!
            </Text>
          </div>
        </div>
      </Header>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-6 col-lg-offset-3">
            <form
              className="kwes-form"
              action="https://kwesforms.com/api/foreign/forms/qszPAjRKNNxEIjrofcDu"
              success-message="Vaša prijava je poslana - čestitamo!"
              error-message="Sva polja moraju biti validna."
              noValidate={true}
            >
              <div className="mb-4">
                <Select
                  name="course"
                  label="Koji tečaj programiranja želiš upisati?"
                  options={[
                    {
                      value: 'frontend',
                      label: 'Frontend programiranje',
                    },
                    {
                      value: 'backend',
                      label: 'Backend programiranje',
                    },
                    {
                      value: 'javascript',
                      label: 'Napredni Javascript',
                    },
                  ]}
                  onChange={(e) => setSelectedCourse(e.value)}
                  required
                  rules="required"
                />
              </div>
              <div className="mb-4">
                <Label forAttribute="ime" isRequired>
                  Ime i prezime
                </Label>
                <Input
                  rules="required"
                  name="ime"
                  type="text"
                  placeholder="Ivana Gospić"
                />
              </div>

              <div className="mb-4">
                <Label forAttribute="adresa" isRequired>
                  Adresa i grad
                </Label>
                <Input
                  name="adresa"
                  type="text"
                  placeholder="Negdje na planeti Zemlji"
                  rules="required"
                />
              </div>

              <div className="mb-4">
                <Label forAttribute="mobitel" isRequired>
                  Mobitel
                </Label>
                <Input
                  name="mobitel"
                  rules="numeric|required"
                  type="text"
                  placeholder="xxxxxxxxx"
                />
              </div>

              <div className="mb-4">
                <Label forAttribute="email" isRequired>
                  Email
                </Label>
                <Input
                  name="email"
                  type="text"
                  placeholder="example@gmail.com"
                  rules="email|required"
                />
              </div>

              <div className="mb-4">
                <Select
                  rules="required"
                  name="occupation"
                  label="Zaposlenje"
                  options={[
                    {
                      value: 'zaposlen',
                      label: 'Zaposlen/a',
                    },
                    {
                      value: 'nezaposlen',
                      label: 'Nezaposlen/a',
                    },
                    {
                      value: 'student',
                      label: 'Student/ica',
                    },
                    {
                      value: 'poduzetnik',
                      label: 'Poduzetnik/ca',
                    },
                    {
                      value: 'umirovljenik',
                      label: 'Umirovljenik/ca',
                    },
                  ]}
                  required
                />
              </div>

              <div className="mb-4">
                <Label forAttribute="iskustvo" isRequired>
                  Prethodno radno iskustvo i edukacije
                </Label>
                <Textarea
                  name="iskustvo"
                  placeholder="Unesi tekst"
                  cols={10}
                  rows={4}
                  rules="required"
                />
              </div>

              <div className="mb-4">
                <Label forAttribute="napomena" isRequired={false}>
                  Napomena
                </Label>
                <Textarea
                  name="napomena"
                  placeholder="Unesi tekst"
                  cols={10}
                  rows={4}
                  rules=""
                />
              </div>

              <div className="mb-4">
                <Select
                  rules="required"
                  name="payment"
                  label="Način plaćanja"
                  options={[
                    ...(selectedCourse === 'javascript'
                      ? [ {
                        value: 'online',
                        label: 'Online uplata - jednokratno plaćanje',
                      },]
                      : [
                          {
                            value: 'rate',
                            label: 'Rate - plaćanje na rate',
                          },
                          {
                            value: 'online',
                            label: 'Online uplata - jednokratno plaćanje',
                          },
                        ]),
                   
                  ]}
                  required
                />
              </div>

              <Button
                text="Prijavi se"
                className="is-pink w-full justify-center"
                type="submit"
              />
            </form>
          </div>
        </div>
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-sm-12 col-lg-8 col-lg-offset-2">
            <Image src={SignupImage} alt="team" />
          </div>
        </div>
      </GlobalSection>
    </Layout>
  );
};

export default Signup;

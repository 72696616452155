import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { useState } from 'react';
import Text from '../Text';
import { ReactSVG } from 'react-svg';
import Quote from '../../images/quote.svg';
import './Gallery.scss';
import GlobalSection from '../Section/GlobalSection';
import { sectionBackgroundColor } from '../../pages/Home';

type Slide = {
  testimonial: string;
  author: string;
};

const Gallery = ({ slides }: { slides: Slide[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    breakpoints: {
      '(min-width: 1200px)': {
        slides: { perView: 2, spacing: 15 },
      },
    },
    slides: {
      perView: 1,
      spacing: 15,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <GlobalSection backgroundColor={sectionBackgroundColor}>
      <Text type="h1" className="text-blue text-center mb-4 xl:mb-7">
        Iskustva polaznika
      </Text>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {slides.map((slide, index) => (
            <div key={index} className="keen-slider__slide relative">
              <Text type="p">{slide.testimonial}</Text>
              <Text type="h4" style={{ marginTop: 50 }}>
                {slide.author}
              </Text>

              <ReactSVG
                src={Quote}
                className="absolute right-3 lg:right-6 bottom-3 lg:bottom-5"
              />
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
    </GlobalSection>
  );
};

function Arrow(props: {
  disabled: boolean;
  left?: boolean;
  onClick: (e: any) => void;
}) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? 'arrow--left' : 'arrow--right'
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
    >
      {props.left && (
        <>
          <rect
            x="35"
            y="0.201172"
            width="33.8135"
            height="35"
            rx="16.9068"
            transform="rotate(90 35 0.201172)"
            fill="#E3386A"
          />
          <path
            d="M22.4875 10.6411L15.7938 17.1079L22.4875 23.5748L20.4167 25.5613L11.6667 17.1079L20.4167 8.65454L22.4875 10.6411Z"
            fill="white"
          />
        </>
      )}
      {!props.left && (
        <>
          {' '}
          <rect
            y="34.0146"
            width="33.8135"
            height="35"
            rx="16.9068"
            transform="rotate(-90 0 34.0146)"
            fill="#E3386A"
          />
          <path
            d="M12.5125 23.5747L19.2063 17.1079L12.5125 10.6411L14.5833 8.6545L23.3333 17.1079L14.5833 25.5613L12.5125 23.5747Z"
            fill="white"
          />
        </>
      )}
    </svg>
  );
}

export default Gallery;

import card1 from './../../images/card1.svg';
import card2 from './../../images/card2.svg';
import card3 from './../../images/card3.svg';
import card4 from './../../images/card4.svg';
import blog1 from './../../images/blog1.jpeg';
import section1 from './../../images/section.jpeg';
import blog3 from './../../images/blog3.png';

export const cards = [
  {
    title: 'Fokus na praksu',
    description:
      'Usvajamo teoriju, ali ju odmah stavljamo u primjenu radom na stvarnim projektima.',
    icon: card4,
    titleType: 'h4',
  },
  {
    title: 'Stručni predavači',
    description:
      'Mentorirat će te iskusni programeri s dugogodišnjim iskustvom rada i podučavanja.',
    icon: card1,
    titleType: 'h4',
  },
  {
    title: 'Online predavanja',
    description:
      'Sve što trebaš je računalo, želja za znanjem i upornost, a nama prepusti vodstvo.',
    icon: card2,
    titleType: 'h4',
  },
  {
    title: 'Čvrsti temelji',
    description:
      'Naučit ćeš sve što trebaš znati za kvalitetan početak karijere u programiranju.',
    icon: card3,
    titleType: 'h4',
  },
];

export const cards2 = [
  {
    title: 'Front-end programiranje',
    description:
      'Ako te zanima korisničko iskustvo i želiš zauzeti ulogu koja je poveznica između dizajna i koda, front-end development je područje za tebe!',
    titleType: 'h3',
    link: '/modul/frontend',
    linkText: 'Pročitaj više',
    buttonClassName: 'mt-4 mb-4 xl:mb-6 xl:mt-6',
  },
  {
    title: 'Back-end programiranje',
    description:
      'Za sve što se događa “iza scene” i omogućava pravilno funkcioniranje web stranice, zadužen/a je backend programer/ka. Možda si to baš ti?',
    titleType: 'h3',
    link: '/modul/backend',
    linkText: 'Pročitaj više',
    buttonClassName: 'mt-4 mb-4 xl:mb-6 xl:mt-6',
  },
];

export const blogCards = [
  {
    title: 'Zašto naučiti programirati?',
    description: 'Puno je razloga: zajednica, budućnost, plaća...',
    image: blog1,
    titleType: 'h4',
    link: 'https://blog.kodiraonica.dev/posts/pet_razloga_za%C5%A1to_nau%C4%8Diti_programirati/',
    linkText: 'Pročitaj tekst',
    buttonClassName: 'float-right mr-4 mt-2 mb-2',
  },
  {
    title: 'Od kuda početi s učenjem?',
    description: 'Kako najlakše započeti s učenjem programiranja?',
    image: blog3,
    titleType: 'h4',
    link: 'https://blog.kodiraonica.dev/posts/od_kuda_poceti_s_ucenjem/',
    linkText: 'Pročitaj tekst',
    buttonClassName: 'float-right mr-4 mt-2 mb-2',
  },
  {
    title: 'Intervju s bivšom polaznicom',
    description: 'Veronika nam je ispričala svoje iskustvo s programiranjem.',
    image: section1,
    titleType: 'h4',
    link: 'https://blog.kodiraonica.dev/posts/intervju_s_bivsom_polaznicom/',
    linkText: 'Pročitaj tekst',
    buttonClassName: 'float-right mr-4 mt-2 mb-2',
  },
];

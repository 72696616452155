import './Card.scss';
import Text from '../../Text';
import { ReactSVG } from 'react-svg';
import Button from '../../Form/Button';
import { CSSProperties } from 'react';
import Image from '../../Image';

type Card = {
  title: string;
  description: string;
  image?: string;
  link?: string;
  linkText?: string;
  titleType: string;
  icon?: string;
  buttonClassName?: string;
};

const CardsSection = ({
  cards,
  descriptionStyle,
  headingStyle,
  cardsColumn,
}: {
  cards: Card[];
  descriptionStyle?: CSSProperties;
  headingStyle?: CSSProperties;
  cardsColumn?: string;
}) => {
  return (
    <div className="cards-section row">
      {cards.map((card: Card, index: number) => (
        <div
          className={`col-xs-12 col-sm-10 col-sm-offset-1 col-md-offset-0 col-md-6 mb-2 ${
            cardsColumn ? cardsColumn : `col-xl-${12 / cards.length}`
          }`}
          key={index}
        >
          {' '}
          <div
            className=" card text-center mb-2 xl:mb-0 overflow-auto"
            style={card.image ? { padding: 0 } : {}}
            key={index}
          >
            {card.icon && (
              <div className="card-image text-center">
                <ReactSVG style={{ margin: '0 auto' }} src={card.icon} />
              </div>
            )}

            {card.image && (
              <div className="card-image text-center">
                <Image src={card.image} alt="" />
              </div>
            )}

            <div className={`${card.image ? 'has-image' : null} card-content`}>
              <Text
                type={card.titleType}
                style={headingStyle}
                className="mb-2 mt-2"
              >
                {card.title}
              </Text>
              <Text type="p" style={descriptionStyle}>
                {card.description}
              </Text>
              {card.link && card.linkText && (
                <Button
                  className={`${card.buttonClassName} is-pink`}
                  text={card.linkText}
                  to={card.link}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsSection;

import './Nav.scss';
import { links } from './Links';
import { Link } from 'react-router-dom';
import Container from '../../Container';
import Dropdown from './Dropdown';
import Button from '../../Form/Button';
import Headroom from 'react-headroom';
import logo from '../../../images/logo.svg';
import MediaQuery from 'react-responsive';
import Sidebar from '../Sidebar';

const Nav = () => {
  const navLinks = links.filter((link) => link.isOnNav);
  return (
    <>
      <MediaQuery maxWidth={1223}>
        <Sidebar />
      </MediaQuery>
      <MediaQuery minWidth={1224}>
        <Headroom>
          <nav>
            <Container>
              <div className="row">
                <ul className="col-lg-10">
                  <li className="flex items-center">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </li>
                  {navLinks.map((link, index) => {
                    if (link.path) {
                      return (
                        <li key={index}>
                          <Link to={link.path}>{link.name}</Link>
                        </li>
                      );
                    }

                    if (link.sublinks) {
                      return (
                        <Dropdown
                          key={index}
                          dropdownName="Tečajevi"
                          options={link.sublinks}
                        />
                      );
                    }

                    return null;
                  })}
                </ul>
                <ul className="col-lg-2">
                  <Button
                    to="/prijavi-se"
                    text="Prijavi se"
                    className="is-pink"
                  />
                </ul>
              </div>
            </Container>
          </nav>
        </Headroom>
      </MediaQuery>
    </>
  );
};

export default Nav;

import { CSSProperties } from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';
const Button = ({
  text,
  className,
  style,
  icon,
  onClick,
  to,
  type,
}: {
  text: string;
  className?: string;
  style?: CSSProperties;
  icon?: React.ReactNode;
  onClick?: () => void;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
}) => {
  const hasMailtTo = to?.includes('mailto:');
  if (hasMailtTo) {
    return (
      <a
        href={to}
        className="is-pink w-full is-large block as-button items-center justify-center"
      >
        {text}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        style={style}
        className={`${className} ${
          icon ? 'flex items-center as-button' : 'as-button'
        }`}
        to={to}
      >
        {text} {icon && <span style={{ marginLeft: 50 }}>{icon}</span>}
      </Link>
    );
  }
  return (
    <button
      style={style}
      className={`${className} ${icon ? 'flex items-center' : ''}`}
      onClick={onClick}
      type={type}
    >
      {text} {icon && <span style={{ marginLeft: 50 }}>{icon}</span>}
    </button>
  );
};

export default Button;

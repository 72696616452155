import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Signup from '../pages/Signup';
import Contact from '../pages/Contact';
import Faq from '../pages/FAQ';
import Modul from '../pages/Modules/Modul';
import NotFoundPage from '../pages/NotFoundPage';
import Legal from '../pages/Legal';
import FocusModul from '../pages/FokusModul';

const AppRoutes = () => (
  <BrowserRouter>
    {/* <Banner /> */}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/prijavi-se" element={<Signup />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/modul/">
        <Route path=":modulId" index={true} element={<Modul />} />
      </Route>
      <Route path="/legal/" element={<Legal />}>
        <Route path=":legalTitle" index={true} element={<Legal />} />
      </Route>
      <Route path="/fokus-tecajevi" element={<FocusModul />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/faq" element={<Faq />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;

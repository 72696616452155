import { ReactSVG } from 'react-svg';
import face from './../../../images/face.svg';
import insta from './../../../images/insta.svg';
import tiktok from './../../../images/tiktok.svg';
import linkedin from './../../../images/linkedin.svg';
import meetup from './../../../images/meetup.svg';
import mail from './../../../images/mail.svg';

export const socialLinks = [
  {
    id: 1,
    path: 'https://www.facebook.com/kodiraonica',
    icon: <ReactSVG src={face} />,
  },
  {
    id: 2,
    path: 'https://www.linkedin.com/company/79854197/',
    icon: <ReactSVG src={linkedin} />,
  },
  {
    id: 3,
    path: 'https://www.instagram.com/kodiraonica/',
    icon: <ReactSVG src={insta} />,
  },
  {
    id: 4,
    path: 'https://www.meetup.com/kodiraonica/',
    icon: <ReactSVG src={meetup} />,
  },
  {
    id: 5,
    path: 'https://www.tiktok.com/@kodiraonica',
    icon: <ReactSVG src={tiktok} />,
  },
  {
    id: 6,
    path: 'mailto:kodiraonica@gmail.com',
    icon: <ReactSVG src={mail} />,
  },
];

export const legalLinks = [
  {
    id: 1,
    path: '/legal/copyright',
    text: 'Copyright',
  },
  {
    id: 2,
    path: '/legal/cookies',
    text: 'Kolačići',
  },
  {
    id: 3,
    path: '/legal/privacy',
    text: 'Politika privatnosti',
  },
];

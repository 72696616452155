import './Input.scss';

const Input = ({
  type,
  placeholder,
  name,
  rules,
  onChange,
}: {
  type: string;
  placeholder: string;
  name: string;
  rules?: string;
  onChange?: (e: any) => void;
}) => {
  return (
    <input
      name={name}
      className="input"
      type={type}
      placeholder={placeholder}
      // @ts-ignore
      rules={rules}
      onChange={onChange}
    />
  );
};

export default Input;

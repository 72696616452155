import { Link } from 'react-router-dom';
import Text from '../../components/Text';
import { Item } from '../../components/Accordion';

export const accordionItems: Item[] = [
  {
    title: 'Hoću li se moći zaposliti nakon Kodiraonice?',
    description: () => (
      <Text type="p">
        Ne možemo ti garantirati zaposlenje, ali možemo stjecanje znanja i
        vještina koje će ti donijeti prvi programerski posao.
      </Text>
    ),
  },
  {
    title: 'Izdajete li diplomu?',
    description: () => (
      <>
        <Text type="p">
          Po završetku tečaja svi polaznici koji su izvršili obaveze i redovito
          prisustvovali edukaciji dobivaju <b>certifikat</b> o uspješnom
          završetku tečaja.
        </Text>
      </>
    ),
  },

  {
    title: 'Kako da znam koji je tečaj za mene?',
    description: () => (
      <Text type="p">
        Ako ne znaš je li za tebe bolja opcija{' '}
        <Link className="underline text-blue" to="/modul/frontend">
          front-end
        </Link>{' '}
        ili{' '}
        <Link className="underline text-blue" to="/modul/backend">
          back-end
        </Link>{' '}
        tečaj programiranja, javi nam se za besplatno savjetovanje. Piši nam
        putem kontakt obrasca ili na{' '}
        <a className="underline text-blue" href="mailto:kodiraonica@gmail.com.">
          kodiraonica@gmail.com.
        </a>
      </Text>
    ),
  },
  {
    title: 'Za koga je Kodiraonica?',
    description: () => (
      <Text type="p">
        Kodiraonica je online škola programiranja namijenjena onima koji su
        nezadovoljni na trenutnom poslu ili fakultetu te žele promijeniti
        karijeru i onima koje jednostavno zanima programiranje, a nikada se nisu
        okušali u tom području.
      </Text>
    ),
  },
  {
    title: 'Trebam li imati predznanje iz programiranja?',
    description: () => (
      <Text type="p">
        Online tečajevi programiranja (front-end development i back-end
        development) namijenjeni su potpunim početnicima.
      </Text>
    ),
  },
  {
    title: 'Postoji li dobna granica za upis tečaja?',
    description: () => (
      <Text type="p">
        Kodiraonica nudi tečajeve programiranja za odrasle punoljetne osobe.
      </Text>
    ),
  },
  {
    title: 'Koliko se često održavaju tečajevi?',
    description: () => (
      <>
        <Text type="p">
          Tečajevi front-end i back-end programiranja održavaju se u prosjeku 2
          puta godišnje. Traju od 5 do 7 mjeseci, ovisno o dinamici rada.
          Ispunjavanjem{' '}
          <Link to="/prijavi-se" className="underline text-blue">
            upisnog obrasca
          </Link>{' '}
          možeš se predbilježiti na listu čekanja. Kontaktirat ćemo te kad se
          približi datum novog tečaja.
        </Text>

        <Text type="p">
          Datume početka <Link to="/fokus-tecajevi">fokus-tečajeva </Link>
          redovito ažuriramo na stranici.
        </Text>
      </>
    ),
  },
  {
    title: 'Mogu li platiti na rate?',
    description: () => (
      <Text type="p">
        Plaćanje na rate je moguće u 6 jednakih mjesečnih rata. Prva rata 
        plaća se prilikom upisa, a ostale mjesečno.
      </Text>
    ),
  },
  {
    title: 'Zašto tečajevi traju po 6 mjeseci?',
    description: () => (
      <>
        {' '}
        <Text type="p">
          Ako si počeo/počela istraživati kako učiti programirati, znaš da je
          potrebno vrijeme i posvećenost. U šest mjeseci online tečaja
          programiranja fokus usmjeravamo na rad na konkretnim projektima.
          Polaznici najbolje uče kroz primjenu znanja, a potrebno je otprilike 5
          do 7 mjeseci za usvajanje strukturiranog znanja iz područja front-end
          ili back-end programiranja.
        </Text>
        <Text type="p">
          Usvojit ćeš osnove koje su vam potrebne za prvi posao u programiranju,
          a svaki sljedeći korak u nadogradnji znanja će samim time postati
          jednostavniji.
        </Text>
      </>
    ),
  },
  {
    title: 'Postoji li mogućnost povrata novca?',
    description: () => (
      <Text type="p">
        Nakon upisa online škole programiranja, povrat novca nije moguć. Tu smo
        za tebe tijekom cijelog procesa i vodimo te do željenog cilja. Ako si
        spriječen/a prisustvovati tečaju, možeš se pridružiti prvoj sljedećoj
        grupi.
      </Text>
    ),
  },
];

import React from 'react';
import Button from '../../Form/Button';
import Text from '../../Text';
import Image from '../../Image';
import { useMediaQuery } from 'react-responsive';

type imageSectionProps = {
  heading: string;
  subHeading?: string;
  description: string | JSX.Element;
  imageSrc: string;
  buttons?: {
    text: string;
    link: string;
    className: string;
    icon?: React.ReactNode;
  }[];
  isReverse?: boolean;
  headingAccentText?: string;
  icon?: React.ReactNode;
  iconLink?: string;
  quote?: string;
  columnLeftClass?: string;
  columnRightClass?: string;
  imageClass?: string;
  imageContent?: React.ReactNode;
};

const ImageSection = ({
  heading,
  subHeading,
  description,
  imageSrc,
  imageClass,
  buttons,
  isReverse,
  headingAccentText,
  icon,
  iconLink,
  quote,
  columnLeftClass,
  columnRightClass,
  imageContent,
}: imageSectionProps) => {
  const shouldReverse = useMediaQuery({
    query: '(min-width: 768px)',
  });
  return (
    <div className="row">
      <div
        className={`${
          columnLeftClass ? `${columnLeftClass}` : 'col-xs-12 col-md-6'
        }`}
        style={isReverse && shouldReverse ? { order: 1 } : { order: -1 }}
      >
        <Text
          type="h1"
          className={headingAccentText ? 'text-white' : 'text-blue'}
          style={
            subHeading
              ? {
                  marginBottom: 10,
                  paddingRight: 20,
                  fontFamily: 'Roboto, sans-serif',
                }
              : { marginBottom: 24, fontFamily: 'Roboto, sans-serif' }
          }
        >
          {heading} <span className="text-pink">{headingAccentText}</span>
        </Text>

        {subHeading && (
          <Text
            type="h5"
            style={{ fontWeight: '400', fontFamily: 'Roboto, sans-serif' }}
          >
            {subHeading}
          </Text>
        )}

        {icon && iconLink && (
          <a
            href={iconLink}
            className="block w-[30px] h-[30px] mb-2 mt-2"
            target="_blank"
            rel="noreferrer"
          >
            {icon}
          </a>
        )}

        <div className="max-w-[600px] mb-6">
          {typeof description === 'string' ? (
            <span
              dangerouslySetInnerHTML={{ __html: description }}
              className={headingAccentText ? 'text-white' : 'text-black'}
            />
          ) : (
            description
          )}
        </div>

        {quote && (
          <div className="quote italic font-bold text-blue mt-[20px]">
            <Text type="p" className="mb-4 lg:max-w-[510px]">
              "{quote}"
            </Text>
          </div>
        )}

        {buttons &&
          buttons?.length > 0 &&
          buttons.map((button, index) => (
            <Button
              style={{ marginRight: 24 }}
              className={button.className}
              text={button.text}
              icon={button.icon}
              key={index}
              to={button.link}
            />
          ))}
      </div>

      <div
        className={`${
          columnRightClass ? `${columnRightClass}` : 'col-xs-12 col-md-6'
        }`}
      >
        <Image src={imageSrc} alt="Hero" className={imageClass} />
        {imageContent && imageContent}
      </div>
    </div>
  );
};

export default ImageSection;

import { BeatLoader } from 'react-spinners';
import useImage from './hooks/useImage';

type ImageProps = {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  loaderColor?: string;
  imageWrapperStyles?: React.CSSProperties;
};

const Image = ({
  src,
  alt,
  className,
  style,
  loaderColor,
  imageWrapperStyles,
}: ImageProps) => {
  const loading = useImage(src);
  return (
    <div
      style={
        loading
          ? {
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 20,
            }
          : imageWrapperStyles
      }
    >
      {loading ? (
        <BeatLoader
          color={loaderColor}
          loading={loading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{
            position: 'absolute',
            top: '0%',
            margin: '20px auto',
          }}
        />
      ) : (
        <img src={src} alt={alt} className={className} style={style} />
      )}
    </div>
  );
};

export default Image;

import './Text.scss';

const Text = ({
  type,
  children,
  className,
  style,
}: {
  type: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const setType = () => {
    switch (type) {
      case 'h1':
        return (
          <h1 style={style} className={className}>
            {children}
          </h1>
        );
      case 'h2':
        return (
          <h2 style={style} className={className}>
            {children}
          </h2>
        );
      case 'h3':
        return (
          <h3 style={style} className={className}>
            {children}
          </h3>
        );
      case 'h4':
        return (
          <h4 style={style} className={className}>
            {children}
          </h4>
        );
      case 'h5':
        return (
          <h5 style={style} className={className}>
            {children}
          </h5>
        );
      case 'h6':
        return (
          <h6 style={style} className={className}>
            {children}
          </h6>
        );
      case 'p':
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      case 'span':
        return (
          <span style={style} className={className}>
            {children}
          </span>
        );
      default:
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
    }
  };

  return setType();
};

export default Text;

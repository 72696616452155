import './Textarea.scss';

const Textarea = ({
  cols,
  rows,
  placeholder,
  name,
  rules,
}: {
  cols: number;
  rows: number;
  placeholder: string;
  name: string;
  rules: string;
}) => {
  return (
    <textarea
      name={name}
      cols={cols}
      rows={rows}
      placeholder={placeholder}
      // @ts-ignore
      rules={rules}
    ></textarea>
  );
};

export default Textarea;

import './Label.scss';

const Label = ({
  children,
  isRequired,
  className,
  forAttribute,
}: {
  children: React.ReactNode;
  isRequired: boolean;
  className?: string;
  forAttribute: string;
}) => {
  return (
    <label htmlFor={forAttribute} className={className}>
      {children}
      {isRequired ? '*' : ''}
    </label>
  );
};
export default Label;

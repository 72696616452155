import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Dropdown.scss';

type Option = {
  value: string;
  path: string;
};

const Dropdown = ({
  dropdownName,
  options,
}: {
  dropdownName: string;
  options: Option[];
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <li className="dropdown" onClick={toggleDropdown}>
      {dropdownName}
      <span
        className={`dropdown__content ${
          isDropdownOpen ? 'is-open' : 'is-closed'
        }`}
      >
        <ul>
          {options.map((option, index) => {
            return (
              <li key={index}>
                <Link to={option.path}>{option.value}</Link>
              </li>
            );
          })}
        </ul>
      </span>
    </li>
  );
};

export default Dropdown;

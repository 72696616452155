import './App.scss';
import AppRoutes from './routes';
import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <>
      <AppRoutes />
      <CookieConsent
        location="bottom"
        buttonText="Shvaćam"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        Ova stranica koristi kolačiće. Klikom na gumb "Shvaćam" ili nastavkom
        korištenja stranice, slažete se s korištenjem kolačića.
      </CookieConsent>
    </>
  );
}
export default App;

export const slides = [
  {
    testimonial: "I kad nešto ne znam, predavač se trudi objasniti. Usmjerava nas prema rješenju, Bitno je da naučimo kako razmišljati kad budemo samostalno programirali.",
    author: "Marko L."
  },
  {
    testimonial:
      'Predavač je fleksibilan oko sadržaja, posvetio je više vremena onome što je grupa zahtijevala i onome u čemu smo najviše štekali. Dosta je vremena bilo posvećeno vježbama, što je super jer te prisiljava da samostalno pišeš kod.',
    author: 'Ivan Vidović',
  },
  {
    testimonial:
      'Svima bih preporučio tečaj u Kodiraonici. Prošao sam različite tečajeve i videe na YouTubeu, ali nigdje nisam dobio toliku količinu znanja kao ovdje. Predavači su jako pristupačni i uvijek spremni pomoći. Na tečaju mi se najviše svidjelo puno praktičnog rada i interakcija tijekom predavanja. Kodiraonica je pravi smjer ako želite postati front-end developer!',
    author: 'Karlo Strunje',
  },
  {
    testimonial:
      'Strukturirana predavanja i vođenje mentora skraćuju muke i upitnike s kojima se svaki početnički programer susreće. Uz Kodiraonicu učenje je lakše i zabavnije!',
    author: 'Valentina Otočan',
  },
  {
    testimonial:
      'Antonija je cool predavačica koja na razumljiv i step-by-step način uvodi u svijet zadavanja naredbi za stvaranje web sadržaja. Kroz zadaće se ponavlja naučeno i fora je vidjeti kako možeš raditi neke svoje stranice! Nije teško, ali treba kuckati!',
    author: 'Monika Ciglar',
  },
  {
    testimonial: 'Uz Kodiraonicu učenje je mnogo lakše jer je gradivo lijepo strukturirano, a predavači strpljivi i uvijek spremni pomoći. Praktični zadaci i zadaće su i korisni i zabavni pa je uz njih lakše steći rutinu učenja. Svima bih preporučila Kodiraonicu',
    author: "Maja Pokos"
  }
];

const Spacer = ({
  height,
  backgroundColor,
}: {
  height: number;
  backgroundColor: string;
}) => {
  return (
    <div style={{ height: height, backgroundColor: backgroundColor }}></div>
  );
};

export default Spacer;

import Antonija from './../../images/Antonija.png';
import Denis from './../../images/Denis.png';
import Matija from './../../images/Matija.png';
import Petra from './../../images/Petra.png';

export const people = [
  {
    name: 'Antonija Šimić',
    title: 'Osnivačica i predavačica',
    mail: 'kodiraonica@gmail.com',
    linkedin: 'https://www.linkedin.com/in/antonija-simic/',
    img: Antonija,
  },
  {
    name: 'Matija Katić',
    title: 'Predavač backend modula',
    mail: 'matkatic@gmail.com',
    img: Matija,
    linkedin: 'https://www.linkedin.com/in/matijakatic/',
  },
  {
    name: 'Denis Martin Budinski',
    title: 'Predavač frontend modula',
    mail: 'denis.budinski@endcoding.hr',
    img: Denis,
    linkedin: 'https://www.linkedin.com/in/denis-martin-budinski/',
  },
  {
    name: 'Petra Sedlanić Kereša',
    title: 'Stručnjakinja za marketing',
    mail: 'kodiraonica@gmail.com',
    img: Petra,
    linkedin: 'https://www.linkedin.com/in/petra-sedlanić/',
  },
];

import { useState } from 'react';
import CallToAction from '../../components/Layout/CallToAction';
import axios from 'axios';

const CtaSection = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const onSubscribe = () => {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMmZhMTc3NmFlYzcyODA4ZGNhN2FhZDI1YTBiYTczMjAxZmY2ZDBlOTkzNjY3ZWI0Njk0ZWI0ZjRmZjdmMDQ0NDI3N2IwZTc4YjQxZTg0ZTUiLCJpYXQiOjE2OTMxNDYzNzYuMTAxODc4LCJuYmYiOjE2OTMxNDYzNzYuMTAxODc5LCJleHAiOjQ4NDg4MTk5NzYuMDk3MjI0LCJzdWIiOiI1OTkyODAiLCJzY29wZXMiOltdfQ.dG_7j5phU-myVrCuVOrTCNobUlv4geD2OM66PPug1QBRGw3lD3Rt0w2gr9LL19JDZaZK6JsmCutWcupgIkCdWAq4Myo_JClHPVxs9fCgaLNslifMnhZP3L9-EKKXj3JLzQKvVZdvY8qhwNLydIc1PPE4BOX-KT6ImZMQe5HrwI7bA-v8p7Z7TvEpM0etqJ9eia3tROBWTRdMrkO0sBON_Mb0qk1zyob3vR5P3sV4wohP2vJtt_tGHUbq4v1oYSvTtdODJX-qzIBm7aSbh9clO4AIRN7MhG_9M0Ec2vVhl5fli3Ma7msFmSfa7QbW6qbrke0m1fwmj4SzMDR6f9gvpoAXm41tNvg-XtADs1hxS5Sva8gLmRLIFHaUwqSyPx8zensCTWvvfCcbd9IvpCIW_2EKtAHskWGDSR3nrTdRGXqKeMJFCsTHSFRFytY57VAB0xhx82bo0Nbatl_UHPqcn6mSQF0QRyMO4nuNs96B5HDr9Lt3nGCnSB66KcMaXB-PK1HidLLZQLyZaLbDLWV_g1_82t8CD7zfZ4cPI4iF62WBEeRQe2py9yuWS9mz3LroOMEyqhXu4AU3Nfnk1j06cpJl0k-lzKtfjjNeOA6aelzv7eNMVkAgRXl2ZVY5hkfb0oYjL29T0WN_m-3phiYONxnqXDg2zjrDcecjKKMPjCY`,
      },
    });
    axiosInstance
      .post('https://connect.mailerlite.com/api/subscribers/', {
        email,
        groups: ['97670088028587509'],
      })
      .then((res) => {
        setSuccessMessage('Uspješno ste se prijavili na newsletter!');
      })
      .catch((err) => {
        setSuccessMessage('Došlo je do greške. Pokušajte ponovo.');
      });
  };
  return (
    <CallToAction
      title="Budi u toku s novostima iz svijeta programiranja! Prijavi se na naš
            newsletter."
      buttonText="Prijavi me"
      hasInput={true}
      inputPlaceholder="Unesi email adresu"
      inputName="subscribe-email"
      onClick={onSubscribe}
      onEmailChange={(e) => setEmail(e.target.value)}
      successMessage={successMessage}
    />
  );
};

export default CtaSection;

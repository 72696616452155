import { ReactSVG } from 'react-svg';
import Button from '../../components/Form/Button';
import Header from '../../components/Header';
import CardsSection from '../../components/Section/CardsSection';
import ImageSection from '../../components/Section/ImageSection';
import Text from '../../components/Text';
import HeaderMockup from './../../images/header-mockup.png';
import HeaderPhone from './../../images/header-phone.png';
import Hero1 from './../../images/image.jpeg';
import Section2 from './../../images/section2.jpeg';
import Vector from './../../images/vector.png';
import ArrowRight from './../../images/arrow-right.svg';
import { cards, cards2 } from './cards';
import './Home.scss';
import Gallery from '../../components/Gallery';
import { slides } from './slides';
import Layout from '../../components/Layout';
import BlogSection from './BlogSection';
import Image from '../../components/Image';
import GradientSection from '../../components/GradientSection';
import CtaSection from './CtaSection';
import GlobalSection from '../../components/Section/GlobalSection';
import { Helmet } from 'react-helmet';

export const sectionBackgroundColor = '#F9F9FA';
export const sectionBlueBackgroundColor = '#231E7C';

const Home = () => (
  <Layout>
    <Helmet>
      <title>Kodiraonica - Upisi u programiranje</title>
      <meta
        name="description"
        content="Prijavi se na tečaj programiranja i postani web developer/ka!"
      />

      <meta
        property="twitter:title"
        content="Kodiraonica - Upisi u programiranje"
      />

      <meta
        property="twitter:description"
        content="Prijavi se na tečaj programiranja i postani web developer/ka!"
      />

      <meta property="og:url" content="https://kodiraonica.dev/" />

      <meta property="og:type" content="website" />

      <meta
        property="og:description"
        content="Prijavi se na tečaj programiranja i postani web developer/ka!"
      />
      <meta property="og:title" content="Kodiraonica - Upisi u programiranje" />
      <meta
        property="og:image"
        content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:site_name" content="Kodiraonica" />
      <meta property="og:locale" content="hr_HR" />
      <meta property="twitter:card" content="summary_large_image" />

      <meta
        property="twitter:image"
        content="https://secure.meetupstatic.com/photos/event/e/b/1/clean_504423761.webp"
      />

      <meta property="twitter:image:width" content="1200" />
      <meta property="twitter:image:height" content="630" />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="programming, coding, Kodiraonica, web development, HTML, CSS, Javascript, React"
      />
    </Helmet>
    <Header className="is-homepage" hasSingleColumn={false}>
      <div>
        <Text className="text-white mb-2" type="h1">
          Postani web programer/ka
        </Text>
        <Text
          style={{ fontWeight: 'normal', maxWidth: 400 }}
          type="h3"
          className="text-white mb-4"
        >
          Unaprijedi svoju karijeru i uđi u IT svijet uz tečajeve
          programiranja!
        </Text>

        <div className="flex flex-col lg:flex-row">
          <Button
            className="is-pink has-max-width mb-1 justify-center lg:mb-0 lg:mr-2"
            text="Prijavi se"
            to="/prijavi-se"
          />
          <Button
            className="is-white has-max-width justify-center"
            to="/about"
            text="Saznaj više"
          />
        </div>
      </div>

      <div style={{ position: 'relative' }} className="hidden xl:block">
        <Image
          src={HeaderPhone}
          alt="Hero"
          loaderColor="#fff"
          style={{
            position: 'absolute',
            height: 411,
            width: 280,
            left: -100,
            top: '80px',
            transform: 'rotate(-9.91deg)',
            zIndex: 1,
          }}
        />

        <Image loaderColor="#fff" src={HeaderMockup} alt="laptop" />
      </div>
    </Header>

    <GlobalSection backgroundColor={sectionBackgroundColor}>
      <CardsSection cards={cards} />
    </GlobalSection>

    <GlobalSection backgroundColor={sectionBackgroundColor}>
      <ImageSection
        heading="Već danas napravi prvi korak prema boljoj budućnosti!"
        description="Promjena karijere nikada nije bila jednostavnija. Uz strukturirane online tečajeve, konkretnu primjenu i naše mentorstvo naučite sve što vam je potrebno za prvi programerski posao!"
        imageSrc={Hero1}
        buttons={[
          {
            text: 'Prijavi se',
            link: '/prijavi-se',
            className: 'is-pink mb-4',
          },
          { text: 'Saznaj više', link: '/about', className: 'is-black mb-4' },
        ]}
      />
    </GlobalSection>

    <GradientSection image={Section2}>
      <Text type="h1" className="text-center text-white mb-4 xl:mb-7">
        Web programiranje za početnike
      </Text>
      <CardsSection cards={cards2} />
    </GradientSection>

    <GlobalSection backgroundColor={sectionBackgroundColor}>
      <ImageSection
        heading="Fokus tečajevi"
        description="Uspješni programeri nikad ne prestaju učiti. Nadogradi znanje uz brze i intenzivne tematske tečajeve!"
        imageSrc={Section2}
        buttons={[
          {
            text: 'Prijavi se',
            link: '/prijavi-se',
            className: 'is-pink mb-4',
          },
          {
            text: 'Saznaj više',
            link: '/fokus-tecajevi',
            className: 'is-black mb-4',
          },
        ]}
      />
    </GlobalSection>

    <GlobalSection
      backgroundColor={sectionBlueBackgroundColor}
      className="pb-0"
    >
      <ImageSection
        heading="Mali tim velikih"
        headingAccentText="ambicija"
        description="Novim generacijama programera želimo olakšati ulazak u IT industriju. Učimo zajedno, radimo na konkretnim projektima i sigurno te vodimo do cilja putem kojim smo i sami prošli!"
        imageSrc={Vector}
        buttons={[
          {
            text: 'Upoznaj nas',
            link: '/about',
            className: 'is-white mb-4',
            icon: <ReactSVG src={ArrowRight} />,
          },
        ]}
        isReverse
      />
    </GlobalSection>

    <BlogSection />

    <CtaSection />

    <Gallery slides={slides} />
  </Layout>
);

export default Home;

export const links = [
  {
    name: 'Tečajevi',
    sublinks: [
      { value: 'Front-end programiranje', path: '/modul/frontend' },
      { value: 'Back-end programiranje', path: '/modul/backend' },
      { value: 'Fokus tečajevi', path: '/fokus-tecajevi' },
    ],
    isOnSidebar: false,
    isOnNav: true,
  },
  {
    name: 'Home',
    path: '/',
    isOnSidebar: true,
    isOnNav: false,
  },
  {
    name: 'Prijavi se',
    path: '/prijavi-se',
    isOnSidebar: true,
    isOnNav: false,
  },
  {
    name: 'Frontend modul',
    path: '/modul/frontend',
    isOnSidebar: true,
    isOnNav: false,
  },
  {
    name: 'Backend modul',
    path: '/modul/backend',
    isOnSidebar: true,
    isOnNav: false,
  },
  {
    name: 'Fokus tečajevi',
    path: '/fokus-tecajevi',
    isOnSidebar: true,
    isOnNav: false,
  },
  {
    name: 'O nama',
    path: '/about',
    isOnSidebar: true,
    isOnNav: true,
  },
  {
    name: 'Česta pitanja',
    path: '/faq',
    isOnSidebar: true,
    isOnNav: true,
  },
  {
    name: 'Blog',
    path: 'https://blog.kodiraonica.dev/',
    isOnSidebar: true,
    isOnNav: true,
  },
  {
    name: 'Kontakt',
    path: '/kontakt',
    isOnSidebar: true,
    isOnNav: true,
  },
  {
    name: "Meetups",
    isOnSidebar: true,
    isOnNav: true,
    path: 'https://www.meetup.com/kodiraonica/',
  },
];

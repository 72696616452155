import Gradient from '../../components/Gradient';
import Container from '../../components/Container';
import './GradientSection.scss';

const GradientSection = ({
  children,
  image,
}: {
  children: React.ReactNode;
  image: string;
}) => {
  return (
    <section className="linear-gradient-section">
      <div
        className="background-image"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%,rgba(0, 0, 0, 0.2) 100%), url(${image})`,
        }}
      ></div>
      <Gradient />
      <Container>{children}</Container>
    </section>
  );
};

export default GradientSection;
